<template>
  <div>
    <FormUser @submit-requested="submitRequested"></FormUser>
  </div>
</template>
 
<script>
import { mapActions } from 'vuex'
import FormUser from '../forms/FormUser.vue'

  export default {
  components: { FormUser },
  methods: {
    ...mapActions('account', ['addUser']),
    submitRequested (userAdd) {
      this.addUser(userAdd);
    },
  }

  }
</script> 
 
<style scoped>
thead > tr > th
{
  text-align: unset !important;
}
</style> 